import React from 'react';
import progressArrow from '../../assests/images/progressArrow.png'
const FinalResultSitStand = ({ value = 9, max = 30 }) => {
  // Calculate position based on sections
  const getArrowPosition = (value) => {
    if (value >= max) {
      return 100; // Return 100% for maximum value
    }
    // Define section boundaries and widths
    const sections = [
      { start: 0, end: 5, width: 16.67 },   // Bad
      { start: 6, end: 10, width: 16.67 },  // Poor
      { start: 11, end: 15, width: 16.67 }, // Normal
      { start: 16, end: 20, width: 16.67 }, // Good
      { start: 21, end: 25, width: 16.67 }, // Healthy
      { start: 26, end: 30, width: 16.67 }  // Super
    ];

    // Find which section the value falls into
    const section = sections.find(s => value >= s.start && value <= s.end);
    
    if (!section) return 0;

    // Calculate previous sections total width
    const previousWidth = sections
      .filter(s => s.start < section.start)
      .reduce((sum, s) => sum + s.width, 0);

    // Calculate position within current section
    const sectionRange = section.end - section.start;
    const valuePosition = value - section.start;
    const sectionPercentage = (valuePosition / sectionRange) * section.width;

    return previousWidth + sectionPercentage;
  };

  const arrowPosition = getArrowPosition(value);

  const isSegmentActive = (start, end) => {
    return value >= start && value <= end;
  };

  const getSegmentStyle = (start, end) => {
    return isSegmentActive(start, end) ? 'bg-opacity-100' : 'bg-opacity-100';
  };

  return (
    <div className="w-full max-w-md mt-6">
      <div className="relative">
        {/* Extended color shade sections */}
        <div className="absolute inset-0 flex w-full h-28 pt-3">
          <div className="w-1/6" style={{ background: 'linear-gradient(180deg, rgba(255, 82, 82, 0.2) 0%, rgba(252, 252, 252, 0.1) 100%)' }} />
          <div className="w-1/6" style={{ background: 'linear-gradient(180deg, rgba(255, 145, 83, 0.2) 0%, rgba(252, 252, 252, 0.1) 100%)' }} />
          <div className="w-1/6" style={{ background: 'linear-gradient(180deg, rgba(255, 213, 79, 0.2) 0%, rgba(252, 252, 252, 0.1) 100%)' }} />
          <div className="w-1/6" style={{ background: 'linear-gradient(180deg, rgba(163, 213, 79, 0.2) 0%, rgba(252, 252, 252, 0.1) 100%)' }} />
          <div className="w-1/6" style={{ background: 'linear-gradient(180deg, rgba(129, 199, 72, 0.2) 0%, rgba(252, 252, 252, 0.1) 100%)' }} />
          <div className="w-1/6" style={{ background: 'linear-gradient(180deg, rgba(56, 142, 60, 0.2) 0%, rgba(252, 252, 252, 0.1) 100%)' }} />
        </div>

        {/* Content container */}
        <div className="relative">
          {/* Value marker */}
          <div 
            className="absolute transform -translate-x-1/2 -top-4"
            style={{ 
              left: `calc(${arrowPosition}% - ${arrowPosition === 100 ? '16px' : '0px'})` // Adjust for arrow width
            }}
          >
            <div className="flex flex-col items-center">
              <img src={progressArrow} alt="progress arrow" />
            </div>
          </div>

          {/* Progress bar container */}
          <div className="relative h-4 rounded-full flex overflow-hidden mt-1">
            <div className={`w-1/6 bg-[#FF5252] ${getSegmentStyle(0, 5)}`} />
            <div className={`w-1/6 bg-[#FF9153] ${getSegmentStyle(6, 10)}`} />
            <div className={`w-1/6 bg-[#FFD54F] ${getSegmentStyle(11, 15)}`} />
            <div className={`w-1/6 bg-[#A3D54F] ${getSegmentStyle(16, 20)}`} />
            <div className={`w-1/6 bg-[#81C748] ${getSegmentStyle(21, 25)}`} />
            <div className={`w-1/6 bg-[#388E3C] ${getSegmentStyle(26, 30)}`} />
          </div>

          {/* Scale markers */}
          <div className="relative h-8 mt-1 mx-2">
            <div className="absolute left-0 text-sm font-medium text-gray-700">0</div>
            <div className="absolute text-sm font-medium text-gray-700" style={{ left: '13.67%' }}>5</div>
            <div className="absolute text-sm font-medium text-gray-700" style={{ left: '30.33%' }}>10</div>
            <div className="absolute text-sm font-medium text-gray-700" style={{ left: '48%' }}>15</div>
            <div className="absolute text-sm font-medium text-gray-700" style={{ left: '65.67%' }}>20</div>
            <div className="absolute text-sm font-medium text-gray-700" style={{ left: '83.33%' }}>25</div>
            <div className="absolute right-0 text-sm font-medium text-gray-700">30</div>
          </div>

          {/* Labels */}
          <div className="flex mt-2">
            <div className="w-1/6 text-center text-sm font-medium">
              <div className=" text-[#FF5252]">Bad</div>
            </div>
            <div className="w-1/6 text-center text-sm font-medium">
              <div className="text-[#FF9153]">Poor</div>
            </div>
            <div className="w-1/6 text-center text-sm font-medium">
              <div className="text-[#FFD54F]">Normal</div>
            </div>
            <div className="w-1/6 text-center text-sm font-medium">
              <div className="text-[#A3D54F]">Good</div>
            </div>
            <div className="w-1/6 text-center text-sm font-medium">
              <div className="text-[#81C748]">Healthy</div>
            </div>
            <div className="w-1/6 text-center text-sm font-medium">
              <div className="text-[#388E3C]">Super</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalResultSitStand;