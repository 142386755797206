import React from "react";
import { Link,useNavigate } from "react-router-dom";
import FinalResult from "./FinalResult";
import FinalResultSitStand from "./FinalResultSitStand";
import upwardArrow from "../../assests/images/upwardArrow.png";
import downwardArrow from "../../assests/images/downwardArrow.png";
import SimpleGreenProgressBar from "./SimpleGreenProgressBar";
import { format } from "date-fns";
const LungCapacityResult = ({ currentValue = 8, data, exercise_enum, lang,accessToken,uuid }) => {
  const navigate = useNavigate();
  const formatDateTime = (timeObj) => {
    const date = new Date(timeObj.time);
    
    // If day is null, format as "11 Nov, 07:15 pm"
    if (!timeObj.day) {
      return format(date, "d MMM, hh:mm aaa");
    }
    
    // If day is "Today", show "Today, 07:15 pm"
    return `${timeObj.day}, ${format(date, "hh:mm aaa")}`;
  };
  const getResultStatus = (value) => {
    if (value < 10) return { text: "poor", color: "#E84545" };
    if (value < 20) return { text: "fair", color: "#FFA500" };
    return { text: "good", color: "#4CAF50" };
  };
  const handleNavigateToLogs = () => {
    const testType =
      exercise_enum === "breathe_hold_test"
        ? "breathe_hold_test"
        : "sit_to_stand_test";
    navigate(`/your-logs?test_type=${testType}`, { state: {accessToken:accessToken , lang:lang , uuid:uuid } });
  };
  const renderSubtitle = () => {
    if (!data?.subtitle) return null;
  
    // Check if the subtitle matches either number format (3s or 26 stands)
    const match = data.subtitle.match(/(.*?)(\d+(?:\s*s|\s+stands))(.*)/);
    
    if (match) {
      const [_, beforeNumber, number, afterNumber] = match;
      return (
        <p className="text-base font-normal">
          {beforeNumber}<span className="font-bold">{number}</span>{afterNumber}
        </p>
      );
    }
  
    // Fallback to regular subtitle if it doesn't match the pattern
    return <p className="text-base font-normal">{data.subtitle}</p>;
  };

  const status = getResultStatus(currentValue);

  return (
    <div className="mt-5 border-[1px] border-[#E6E6E6] rounded-md text-center py-6 px-3 bg-[#FCFCFC]">
      <div>
        <p className="text-xl font-bold text=[#000]">{data?.title}</p>
        <p className="text-base font-normal">
        {renderSubtitle()}
          {/* holding breath for <span className="font-bold">{currentValue}s</span> */}
        </p>
      </div>

      <div className="pt-2">
        {exercise_enum === "breathe_hold_test" ? (
          //   <FinalResult value={data?.score} max={finalResultMax} />
          <SimpleGreenProgressBar
            value={data?.score}
            exercise_enum="breathe_hold_test"
          />
        ) : (
          <SimpleGreenProgressBar
            value={data?.score}
            exercise_enum="sit_to_stand_test"
          />
        )}
      </div>
      {data?.show_prev_and_best_score && (
        <div className="flex mt-2 justify-around">
          <div>
            <p className="uppercase text-sm text-[#A7A7A7] font-normal">
              {data?.previous_score?.title}
            </p>
            <p className="text-xs flex gap-1 items-center text-[#212121] font-normal">
              <span className="font-semibold pr-1">
                {data?.previous_score?.score}
              </span>
              {data?.previous_score?.score_type === "upward" ? (
                <img width={10} height={10} src={upwardArrow} />
              ) : (
                <img width={10} height={10} src={downwardArrow} />
              )}
              {data?.previous_score?.time &&
                formatDateTime(data?.previous_score?.time)}
            </p>
          </div>
          <div>
            <p className="uppercase text-sm text-[#A7A7A7] font-normal">
              {data?.best_score?.title}
            </p>
            <p className="text-xs flex gap-1 items-center text-[#212121] font-normal">
              <span className="font-semibold pr-1">
                {data?.best_score?.score}
              </span>
              {data?.best_score?.score_type === "upward" ? (
                <img width={10} height={10} src={upwardArrow} />
              ) : (
                <img width={10} height={10} src={downwardArrow} />
              )}
              {data?.best_score?.time && formatDateTime(data?.best_score?.time)}
            </p>
          </div>
        </div>
      )}

      <div className="text-center mt-5 cursor-pointer">
        <div
          onClick={handleNavigateToLogs}
          className="text-[#979797] text-xs font-semibold uppercase underline-offset-2 underline"
        >
          {lang === "hi"
            ? "अपने परिणाम सारांश देखें"
            : "View Your Results Summary"}
        </div>
      </div>
    </div>
  );
};

export default LungCapacityResult;
