import React from 'react';
import FinalResult from './FinalResult';
import thumbsup from '../../assests/images/thumpsup.png';
import thumbsdown from '../../assests/images/thumpsdown.png';
import exeriseimage from '../../assests/images/exeriseimage.png';
import NextArrow from '../../assests/images/NextArrow.png';
import { Link } from 'react-router-dom';
import FinalResultSitStand from './FinalResultSitStand';
import closeIcon from '../../assests/images/CloseIcon.png'
const ResultScreenSittoStand = () => {
  return (
    <div className="w-full max-w-2xl px-6 py-2">
      <div className="relative w-full max-w-sm px-4 py-3">
      <button 
        // onClick={onClose}
        className="absolute right-0 top-2 p-1 rounded-full hover:bg-gray-100"
        aria-label="Close"
      >
        <img src={closeIcon}/>
      </button>
      
      <div className="text-center">
        <p className="text-2xl font-bold text-center">Your results</p>
        <p className="text-xs text-[#A7A7A7] font-normal leading-5">Today, 10:15 am</p>
      </div>
    </div>
      <div className="border-[1px] border-[#E6E6E6] rounded-md text-center py-10 px-3 bg-[#FCFCFC] mt-3">
        <div>
          <p className="text-xl text-[#E84545] font-bold">You have poor lung capacity</p>
          <p className="mt-3 text-base font-normal">
            holding breath for <span className="font-bold">8s</span>{' '}
          </p>
        </div>
        <div>
          <FinalResultSitStand value={29} max={40}/>
        </div>
        <div className='flex mt-12 justify-around'>
            <div className=''>
                <p className='uppercase text-sm text-[#A7A7A7] font-normal'>Your Previous</p>
                <p className='text-xs text-[#212121] font-normal'><span className='font-semibold pr-1'>5s</span>Today, 10:13 am</p>
            </div>
            <div>
                <p className='uppercase text-sm text-[#A7A7A7] font-normal'>Your Best</p>
                <p className='text-xs text-[#212121] font-normal'><span className='font-semibold pr-1'>5s</span>Today, 10:13 am</p>
            </div>
        </div>
        <div className='text-center mt-2'>
            <Link className='text-[#979797] text-xs font-semibold uppercase underline-offset-2 underline'>View Your Results Summary</Link>
        </div>
      </div>
      <div className="my-4">
        <p className="uppercase text-sm text-[#A7A7A7] font-semibold mb-2">How was the test?</p>
        <div className="border-[1px] border-[#E6E6E6] rounded-md text-center py-5 px-3 bg-[#FCFCFC]">
          <div className="flex items-center justify-around">
            <div className="flex flex-col justify-center items-center">
              <img className="w-8 h-8" src={thumbsup} />
              <p className="uppercase text-sm font-medium mt-2">Liked it</p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <img className="w-8 h-8" src={thumbsdown} />
              <p className="uppercase text-sm font-medium mt-2">Not for me</p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-4">
        <p className="uppercase text-sm text-[#A7A7A7] font-semibold mb-2">Increase your lung capacity</p>
        <div className="border-[1px] border-[#E6E6E6] rounded-md text-center p-3 bg-[#FCFCFC]">
          <div className='flex items-center'>
            <img className='w-14 h-14' src={exeriseimage} />
            <div className='text-left pl-3'>
              <p className="text-sm font-semibold">Practise Breathing Exercise</p>
              <p className='text-xs text-[#A5A5A5]'>Boost your lung capacity and breathing efficiency.</p>
            </div>
            <div>
                <img src={NextArrow}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultScreenSittoStand;
