// FeedbackSection.js
import React, { useState, useRef } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import { BASE_URL } from "../../utils/ApiConstants";
import likeLottieData from "../../assests/json/Like.json";
import dislikeLottieData from "../../assests/json/Dislike.json";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";

const FeedbackButton = ({ 
  type, 
  label, 
  onClick, 
  disabled, 
  isActive, 
  lottieData,
  activeColor ,
  lang
}) => {
  const lottieRef = useRef(null);

  const handleClick = () => {
    if (!disabled) {
      onClick();
      if (lottieRef.current) {
        lottieRef.current.play();
      }
    }
  };

  return (
    <button
      className={`flex flex-col relative justify-center items-center ${
        disabled && !isActive ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:opacity-80'
      }`}
      onClick={handleClick}
      disabled={disabled && !isActive}
      style={{
        color: isActive ? activeColor : 'inherit'
      }}
    >
      <div className="w-24 h-24">
        <Lottie
          lottieRef={lottieRef}
          animationData={lottieData}
          loop={false}
          autoplay={false}
          className="w-24 h-24"
        />
      </div>
      <p 
        className="absolute bottom-2 uppercase text-sm font-medium"
        style={{
          color: isActive ? activeColor : 'inherit'
        }}
      >
        {label}
      </p>
    </button>
  );
};

export const FeedbackSection = ({ accessToken,exercise_enum,lang,uuid,app_aging }) => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [feedbackType, setFeedbackType] = useState(null);

  const handleFeedback = async (isPositive) => {
    if (submitted) return;

    try {
      const response = await axios({
        method: 'post',
        url: `${BASE_URL}/exercise-test/feedback`,
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'appVersion': '6.5',
          'deviceBrand': 'OnePlus',
          'deviceModel': 'HD1901',
          'device_name': 'OnePlus 7TT',
          'deviceVersion': '12',
          'platform': 'android',
          'timestamp': Date.now().toString(),
          'accesstoken': accessToken,
          'Access-Control-Allow-Origin': '*'
        },
        data: {
          feedback_data: {
            liked: isPositive,
            not_for_me: !isPositive
          },
          exercise_enum: exercise_enum
        }
      });

      if (response.status === 200) {
        const analyticsPayload = {
          like_status: isPositive ? "Yes" : "No",
          screen_name:exercise_enum,
          session_source:'organic',
          app_aging:app_aging
        };
        sendEventsToAnalyticsSystems("BE_result_screen_tap", analyticsPayload,uuid);
        setSubmitted(true);
        setError(null);
        setFeedbackType(isPositive ? 'like' : 'dislike');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Unable to submit feedback. Please try again.');
      console.error('Feedback submission error:', err);
    }
  };

  return (
    <div className="my-5">
      <p className="uppercase text-sm text-gray-400 font-semibold mb-2">
        {
          lang === 'hi' ? 'परीक्षण कैसा था?' :'How was the test?'
        }
      </p>
      <div className="border border-gray-200 rounded-md text-center pb-2 px-3 bg-gray-50">
        <div className="flex items-center justify-around">
          <FeedbackButton
            type="positive"
            label={`${lang === 'hi' ? 'यह पसंद है' :'Liked it' }`}
            onClick={() => handleFeedback(true)}
            disabled={submitted}
            isActive={feedbackType === 'like'}
            lottieData={likeLottieData}
            activeColor="#33A854"
          />
          <FeedbackButton
            type="negative"
            label={`${lang === 'hi' ? 'मेरे लिए नहीं' :'Not for me' }`}
            onClick={() => handleFeedback(false)}
            disabled={submitted}
            isActive={feedbackType === 'dislike'}
            lottieData={dislikeLottieData}
            activeColor="#EF4444"
          />
        </div>
        {error && (
          <p className="text-[#E23D2B] text-sm mt-2">{error}</p>
        )}
      </div>
    </div>
  );
};

export default FeedbackSection;