import React from "react";
import FinalResult from "./FinalResult";
import exeriseimage from "../../assests/images/exeriseimage.png";
import NextArrowBlack from "../../assests/images/NextBlue.svg";
import Time from "../../assests/images/Time.png";
import TimeMachine from "../../assests/images/TimeMachine.png";
import BreathHoldTestProgressBar from "./BreathHoldTestProgressBar";
import SitToStandTestProgressBar from "./SitToStandTestProgressBar";
import FinalResultSitStand from "./FinalResultSitStand";
import star from "../../assests/images/Star.svg";
import Legs from "../../assests/images/Legs.svg";
import LegsBlue from "../../assests/images/LegsBlue.svg"
import SimpleProgressBarLogScreen from "./SimpleProgressBarLogScreen";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";

const SitToStandTest = ({ testType, data, uuid,formatDateTime,lang }) => {
  const filteredData = data?.tabs?.filter(
    (exerciseData) => exerciseData.tab_type === testType
  );
  const handleBanner = (navigateType,exercise) => {
    const exerciseName = testType === 'sit_to_stand_test' ? 'SitToStandTestsSummaryScreen' : 'BreatheHoldTestSummaryScreen';
    let reactNativePayload = {
      key: 203,
      data: {
        navigate_url: navigateType,
        navigate_type: "INTERNAL",
        navigate_from:exerciseName,
        from:"webView"
      },
    };

    if (window?.ReactNativeWebView) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify(reactNativePayload)
      );
    }
    const analyticsPayload = {
      User_id:uuid,
      tap_value: exercise,
      screen_name:testType,
      session_source:'organic',
    };
    sendEventsToAnalyticsSystems("log_screen_tap", analyticsPayload,uuid);
  };
  const ImageURL = `${process.env.REACT_APP_S3_BUCKET_URL}`;
  return (
    <div className="px-6">
      {filteredData[0]?.best_score !== null && (
        <div>
          {/* <FinalResultSitStand /> */}
          <div className="border-[1px] rounded-xl border-[#A74EF5] bg-[#F6EBFF] px-4 py-4">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <img className="w-4 h-4" src={star} />
                <p className="text-sm text-[#924DF8] font-medium">
                  {data?.best_score_title}
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <img className="w-4 h-4" src={LegsBlue} />
                <p className="text-sm font-bold text-[#924DF8]">
                  {filteredData[0]?.best_score}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {filteredData[0]?.recommendations?.data.length !== 0 && (
        <div className="mt-7">
          <p className="text-base font-semibold">
            {filteredData[0]?.recommendations?.title}
          </p>
          {filteredData[0]?.recommendations?.data.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => handleBanner(item.navigate_url, item.title)}
                className="cursor-pointer mt-1.5 border-[1px] border-[#E6E6E6] rounded-md text-center p-3 bg-[#FCFCFC]"
              >
                <div className="flex items-center gap-3">
                  <img
                    className="w-14 h-14"
                    src={`${ImageURL}/${item?.image}`}
                  />
                  <div className="text-left">
                    <p className="text-sm font-semibold ">{item?.title}</p>
                    <p className="text-xs text-[#A5A5A5] ">{item?.subtitle}</p>
                  </div>
                  <div className="w-5 h-5 min-w-5">
                    <img src={NextArrowBlack} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="mt-8">
        <p className="text-base font-semibold">{lang === "hi" ? "आपका परीक्षा परिणाम" : "Your test results"}</p>
        {filteredData[0]?.logs?.map((item) => {
          return (
            <div className="mt-4">
              <div className="flex gap-2 mt-2.5 items-center">
                <img className="w-4 h-4" src={Time} />
                <p className="text-[#A7A7A7] text-xs">
                  {formatDateTime(item?.time)}
                </p>
              </div>
              <div className="mt-2 border-[1px] border-[#E6E6E6] rounded-md text-center p-4 bg-[#FCFCFC]">
                <div className="flex items-center justify-between ">
                  <p className="text-[#3D3D3D] text-xs font-normal">
                    {item?.data[0]?.text}
                  </p>
                  <div className="flex items-center gap-2">
                    <img className="w-4 h-4" src={Legs} />
                    <p className="text-sm font-medium text-[#000000]">
                      {item?.data[0]?.value}
                    </p>
                  </div>
                </div>
                {item?.data[1]?.value &&
                  Array.isArray(item?.data[1]?.value) &&
                  item?.data[1]?.value.length > 0 && (
                    <div className="flex items-start justify-between mt-1">
                      <p className="text-[#3D3D3D] text-xs font-normal">
                        {item?.data[1]?.text}
                      </p>
                      <div className="w-4/5 text-right">
                        <p className="text-sm font-medium text-[#000000] whitespace-pre-line">
                          {item?.data[1]?.value
                            .toString()
                            .split(",")
                            .join(",\n")}
                        </p>
                      </div>
                    </div>
                  )}

                <div className="pt-5">
                  <SimpleProgressBarLogScreen
                    value={item?.data[0]?.value}
                    exercise_enum="sit_to_stand_test"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SitToStandTest;
