import React from "react";
import exeriseimage from "../../assests/images/exeriseimage.png";
import NextArrow from "../../assests/images/NextArrow.svg";
import { Link } from "react-router-dom";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
export const LungCapacitySection = ({ data,exercise_enum,uuid }) => {
  const lungHealthResultExercise = data;
  const ImageURL = `${process.env.REACT_APP_S3_BUCKET_URL}`;
  
  const handleBanner =(navigateType,exercise)=>{
    const exerciseName = exercise_enum === 'sit_to_stand_test' ? 'SittoStandResult' : 'BreatheHoldTestResult';
    let reactNativePayload = {
        key: 203,
        data: {
          navigate_url: navigateType,
          navigate_type: 'INTERNAL',
          from: 'webView',
          navigate_from:exerciseName
        },
      };
  
      if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(reactNativePayload));
      }
      const analyticsPayload = {
        User_id:uuid,
        tap_value: exercise,
        screen_name:exercise_enum
      };
      sendEventsToAnalyticsSystems("BE_result_screen_tap", analyticsPayload,uuid);
  }
  return (
    <div className="my-4">
      <p className="uppercase text-sm text-[#A7A7A7] font-semibold mb-2">
        {lungHealthResultExercise?.title}
      </p>
      {lungHealthResultExercise?.data?.map((exercise, i) => {
        return (
          <div
            key={i}
            onClick={() => handleBanner(exercise.navigate_url,exercise.title)}
            className="cursor-pointer border-[1px] border-[#E6E6E6] rounded-md text-center p-3 mt-2 bg-[#FCFCFC]"
          >
            <div className="flex gap-3 items-center">
              <img
                className="w-14 h-14"
                src={`${ImageURL}/${exercise.image}`}
                alt="Exercise"
              />
              <div className="text-left flex-grow">
                <p className="text-sm font-semibold">{exercise.title}</p>
                <p className="text-xs text-[#A5A5A5]">{exercise.subtitle}</p>
              </div>
              <div className="w-5 h-5 min-w-5">
                <img src={NextArrow} alt="Next" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
