import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Backicon from "../../assests/images/BackIcon.png";
import BreathHoldTest from "./BreathHoldTest";
import SitToStandTest from "./SitToStandTest";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
import Loader from "../exercise/Loader";
import axios from "axios";
import { BASE_URL } from "../../utils/ApiConstants";
import { format, parseISO } from 'date-fns';
const TEST_COMPONENTS = {
  breathe_hold_test: BreathHoldTest,
  sit_to_stand_test: SitToStandTest,
};

const YourLogs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const typeFromUrl = queryParams.get("test_type")?.toLowerCase();

  const [activeTest, setActiveTest] = useState(
    typeFromUrl || "breathe_hold_test"
  );
  const [isLoading, setIsLoading] = useState(true);
  const [logsData, setLogsData] = useState([]);
  const [uuid, setUuid] = useState(state?.uuid || "");
  const [accessToken, setaccessToken] = useState(state?.accessToken || "");
  const [lang, setLang] = useState(state?.lang ||"");
  const [appVersion, setAppVersion] = useState("");

  useEffect(() => {
    const listenMessage = (msg) => {
      try {
        // Check if msg.data exists
        if (!msg.data) return;

        // Handle the message based on its type
        let messageData;
        if (typeof msg.data === 'string') {
          // Only try to parse if it's a string and looks like JSON
          if (msg.data.startsWith('{') || msg.data.startsWith('[')) {
            try {
              messageData = JSON.parse(msg.data);
            } catch (e) {
              console.warn('Failed to parse message data as JSON:', e);
              return;
            }
          } else {
            // Not JSON string, skip processing
            return;
          }
        } else if (typeof msg.data === 'object') {
          // If it's already an object, use it directly
          messageData = msg.data;
        } else {
          // Unknown data type, skip processing
          return;
        }

        // Extract profile data if it exists
        const profileData = messageData?.data?.profileData;
        if (profileData) {
          setUuid(profileData.uuid || '');
          if (!state?.accessToken) {
            setaccessToken(profileData.accessToken || '');
          }
          setLang(profileData.language || '');
          setAppVersion(profileData.appVersion || '');
        }
      } catch (error) {
        console.warn('Error processing message:', error); 
      }
    };

    // Add event listeners
    if (document.addEventListener) {
      document.addEventListener("message", listenMessage, false);
    } else if (document.attachEvent) {
      document.attachEvent("onmessage", listenMessage);
    }

    if (window.addEventListener) {
      window.addEventListener("message", listenMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", listenMessage);
    }

    // Cleanup function
    return () => {
      if (document.removeEventListener) {
        document.removeEventListener("message", listenMessage);
      } else if (document.detachEvent) {
        document.detachEvent("onmessage", listenMessage);
      }

      if (window.removeEventListener) {
        window.removeEventListener("message", listenMessage);
      } else if (window.detachEvent) {
        window.detachEvent("onmessage", listenMessage);
      }
    };
  }, []);
  const updateUrlParams = (testType) => {
    const newUrl = `${location.pathname}?test_type=${testType}`;
    navigate(newUrl, { replace: false });
  };

  const handleTestChange = (testType) => {
    setActiveTest(testType);
    updateUrlParams(testType);
  };

  const handleBackToHome = () => {
    let reactNativePayload = { type: "HIDE_INTERNAL_PWA", key: 207 };
    window?.ReactNativeWebView?.postMessage(JSON.stringify(reactNativePayload));
  };

  // Combined useEffect for initial setup and API call
  useEffect(() => {
    const initializeComponent = async () => {
      // Handle URL params
      const type = queryParams.get("test_type");
      if (!type) {
        updateUrlParams("breathe_hold_test");
      }
      if (accessToken) {
        await fetchLogs();
      }
    };

    initializeComponent();
  }, [activeTest,accessToken, appVersion]); // Dependencies include activeTest to reload data when test type changes
  // API call function
  const fetchLogs = async () => {
    try {
      setIsLoading(true);
      const response = await axios({
        method: "GET",
        url: `${BASE_URL}/exercise-test/logs`,
        headers: {
          appVersion: appVersion,
          accesstoken: accessToken
        },
      });

      if (response.data) {
        setLogsData(response.data);
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
      // Handle error appropriately - maybe set an error state
    } finally {
      setIsLoading(false);
    }
  };

  // Effect for React Native communication
  useEffect(() => {
    let reactNativePayload = { type: "HIDE_BACK_ARROW", key: 205 };
    window?.ReactNativeWebView?.postMessage(JSON.stringify(reactNativePayload));
  }, []);

  // Effect for analytics
  useEffect(() => {
    const payload = {
      screen_name:
        activeTest === "breathe_hold_test"
          ? "Breathe Hold Test"
          : "Sit To Stand Test",
      index_selected:
        activeTest === "breathe_hold_test"
          ? "Breathe Hold Test"
          : "Sit To Stand Test",
          User_id:uuid,
          session_source:'organic',
    };
    sendEventsToAnalyticsSystems("log_screen", payload,uuid);
  }, [activeTest]);
  const onStartExercise = () =>{
    let reactNativePayload = {
      key: 203,
      data: {
        navigate_url: 'breathing_exercise',
        navigate_type: 'INTERNAL',
        from:"webView"
      },
    };

    if (window?.ReactNativeWebView) {
      window?.ReactNativeWebView?.postMessage(JSON.stringify(reactNativePayload));
    }
  }
  const TestComponent = TEST_COMPONENTS[activeTest];
  const formatDateTime = (timeObj) => {
    const { day, time } = timeObj;
    const parsedDate = parseISO(time);
    
    // If day is present (e.g., "Today"), use that with just the time
    if (day) {
      return `${day}, ${format(parsedDate, 'hh:mm a')}`;
    }
    
    // If no day specified, use full date format
    return format(parsedDate, 'dd MMM, hh:mm a');
  };
  return (
    <div className="py-6 bg-white min-h-svh">
      <nav className="w-full">
        <div className="max-w-screen-xl flex flex-wrap items-center mx-auto px-4 gap-2">
          <button onClick={handleBackToHome}>
            <img src={Backicon} alt="Back Arrow Icon" />
          </button>
          <p className="text-xl font-semibold">{logsData?.data?.header}</p>
        </div>
      </nav>
      <div className="flex items-center space-x-2 p-6">
        <div className="flex items-center space-x-2 border-[1px] border-gray-300 p-1 rounded-full overflow-hidden">
          {logsData?.data?.tabs.map((item) => {
            return (
              <button
                onClick={() => handleTestChange(`${item?.tab_type}`)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                  activeTest === `${item?.tab_type}`
                    ? "bg-[#72B53D] text-white"
                    : "text-gray-600"
                }`}
              >
                <span className="line-clamp-1">
                {item?.title}
                </span>
                
              </button>
            );
          })}

          {/* <button
            onClick={() => handleTestChange('sit_to_stand_test')}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
              activeTest === 'sit_to_stand_test' ? 'bg-[#72B53D] text-white' : 'text-gray-600'
            }`}
          >
            Sit to Stand Test
          </button> */}
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        TestComponent &&
        (logsData?.data?.tabs.find((tab) => tab.tab_type === activeTest)?.logs
          ?.length === 0 ? (
            <div className="flex flex-col items-center justify-center min-h-[400px] p-6">
            <p className="text-lg text-gray-700 mb-6 text-center">
              Complete the exercise to unlock your logs
            </p>
            <button
              onClick={onStartExercise}
              className="bg-[#72B53D] text-white px-6 py-3 rounded-full font-medium hover:bg-[#5f9934] transition-colors duration-200"
            >
              Start Exercise
            </button>
          </div>
        ) : 
        (
          <TestComponent
            testType={activeTest}
            data={logsData?.data}
            uuid={uuid}
            lang={lang}
            formatDateTime={formatDateTime}
          />
        )
      )
      )}
    </div>
  );
};

export default YourLogs;
