import React, { useState, useEffect } from "react";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
import { BASE_URL } from "../../utils/ApiConstants";
import Loader from '../exercise/Loader';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const TrackingModal = ({ isOpen, onClose, onSubmit, onRestart, exerciseData, lang, uuid, accessToken, appAging }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [trackingModalData, setTrackingModalData] = useState(exerciseData?.data?.questionnaire || []);
  const [validationError, setValidationError] = useState("");
  const navigate = useNavigate();

  // Localized validation error messages
  const validationMessages = {
    hi: {
      noSymptoms: "कृपया कम से कम एक लक्षण चुनें",
      noStands: "कृपया स्टैंड की संख्या दर्ज करें",
      invalidStands: "कृपया 1 और 30 के बीच एक वैध संख्या दर्ज करें",
      submitError: "सहेजने में विफल। कृपया पुनः प्रयास करें"
    },
    en: {
      noSymptoms: "Please select at least one symptom",
      noStands: "Please enter the number of stands",
      invalidStands: "Please enter a valid number between 1 and 30",
      submitError: "Failed to save. Please try again."
    }
  };

  const getValidationMessage = (messageKey) => {
    return validationMessages[lang]?.[messageKey] || validationMessages['en'][messageKey];
  };

  const api = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      appVersion: "6.5",
      deviceBrand: "OnePlus",
      deviceModel: "HD1901",
      device_name: "OnePlus 7TT",
      deviceVersion: "12",
      platform: "android",
      "Access-Control-Allow-Origin": "*",
      accesstoken: accessToken,
    },
  });

  const [answers, setAnswers] = useState(() => {
    const symptomsQuestion = trackingModalData.find(q => q.options);
    const initialSymptoms = {};
    symptomsQuestion?.options.forEach(option => {
      initialSymptoms[option] = false;
    });

    return {
      symptoms: initialSymptoms,
      stands: ""
    };
  });

  useEffect(() => {
    if (isOpen) {
      const payload = {
        screen_name: "sit_to_stand",
        User_id: uuid,
        app_aging:appAging,
        session_source:'organic',
        screen_name:exerciseData?.data?.exercise_enum

      };
      sendEventsToAnalyticsSystems("sit_to_stand_symptoms", payload, uuid);
    }
    if (isOpen && currentStep === 1) {
      const payload = {
        screen_name: "sit_to_stand",
        User_id: uuid,
        app_aging:appAging,
        session_source:'organic',
        screen_name:exerciseData?.data?.exercise_enum
      };
      sendEventsToAnalyticsSystems("sit_to_stand_stand_count", payload, uuid);
    }
  }, [isOpen,currentStep]);

  if (!isOpen) return null;

  const getCurrentQuestion = () => {
    return trackingModalData[currentStep] || {};
  };

  const validateSymptoms = () => {
    const hasSelectedSymptom = Object.values(answers.symptoms).some(value => value);
    if (!hasSelectedSymptom) {
      setValidationError(getValidationMessage('noSymptoms'));
      return false;
    }
    return true;
  };

  const validateStands = () => {
    if (!answers.stands) {
      setValidationError(getValidationMessage('noStands'));
      return false;
    }
    const standsCount = parseInt(answers.stands, 10);
    if (isNaN(standsCount) || standsCount < 1 || standsCount > 30) {
      setValidationError(getValidationMessage('invalidStands'));
      return false;
    }
    return true;
  };

  const handleSymptomChange = (symptom) => {
    setValidationError(""); // Clear validation error when user makes a selection
    if (symptom === "No symptoms") {
      const resetSymptoms = {};
      Object.keys(answers.symptoms).forEach(key => {
        resetSymptoms[key] = key === "No symptoms";
      });
      setAnswers(prev => ({
        ...prev,
        symptoms: resetSymptoms
      }));
    } else {
      setAnswers(prev => ({
        ...prev,
        symptoms: {
          ...prev.symptoms,
          [symptom]: !prev.symptoms[symptom],
          "No symptoms": false
        }
      }));
    }
  };

  const submitExerciseTest = async (formData) => {
    const { symptoms, stands } = formData;
    
    const sitToStandSymptoms = Object.entries(symptoms).map(([symptom, value]) => ({
      [symptom]: value
    }));
  
    const payload = {
      exercise_id: exerciseData?.data?.exercise_id,
      exercise_enum: exerciseData?.data?.exercise_enum,
      sit_to_stand_count: parseInt(stands, 10),
      sit_to_stand_symptoms: sitToStandSymptoms
    };
  
    try {
      const { data } = await api.post('/add/exercise-test/response', payload, {
        headers: {
          timestamp: Date.now().toString()
        }
      });
      if (data?.response?.code === 200) {
        navigate('/result-screen', { 
          state: { 
            accessToken: accessToken,
            exercise_enum: exerciseData?.data?.exercise_enum,
            lang: lang
          }
        });
      }
      return data;
    } catch (error) {
      console.error('Error submitting exercise test:', error);
      throw error;
    }
  };

  const handleContinue = async () => {
    setValidationError(""); // Clear previous validation errors

    if (currentStep < trackingModalData.length - 1) {
      if (!validateSymptoms()) return;
      
      setCurrentStep(prev => prev + 1);
      const selectedSymptoms = Object.entries(answers.symptoms)
        .filter(([_, value]) => value)
        .map(([key]) => key);
      
      const payload = {
        screen_name: "sit_to_stand",
        tap_type: selectedSymptoms,
        tap_value: "continue",
        User_id: uuid,
        app_aging:appAging,
        screen_name:exerciseData?.data?.exercise_enum
      };
      sendEventsToAnalyticsSystems("sit_to_stand_symptoms_tap", payload, uuid);
    } else {
      if (!validateStands()) return;

      try {
        await submitExerciseTest({
          symptoms: answers.symptoms,
          stands: answers.stands,
        });
        
        onSubmit({
          symptoms: answers.symptoms,
          stands: parseInt(answers.stands, 10),
        });
        onClose();
        setCurrentStep(0);
        setAnswers(prev => ({ ...prev, stands: "" }));
        
        const payload = {
          screen_name: "sit_to_stand",
          tap_type: answers.stands,
          tap_value: "save",
          User_id: uuid,
          app_aging:appAging,
          screen_name:exerciseData?.data?.exercise_enum
        };
        sendEventsToAnalyticsSystems("sit_to_stand_stand_count_tap", payload, uuid);
      } catch (error) {
        console.error('Failed to submit exercise test:', error);
        setValidationError(getValidationMessage('submitError'));
      }
    }
  };

  const handleSkip = () => {
    setValidationError(""); // Clear validation error when skipping
    const resetSymptoms = {};
    Object.keys(answers.symptoms).forEach(key => {
      resetSymptoms[key] = false;
    });
    
    setAnswers(prev => ({
      ...prev,
      symptoms: resetSymptoms
    }));
    
    setCurrentStep(prev => prev + 1);
    const payload = {
      screen_name: "sit_to_stand",
      tap_value: "skip",
      User_id: uuid,
      app_aging:appAging,
      screen_name:exerciseData?.data?.exercise_enum
    };
    sendEventsToAnalyticsSystems("sit_to_stand_symptoms_tap", payload, uuid);
  };

  const handleRestart = () => {
    setValidationError(""); // Clear validation error when restarting
    const symptomsQuestion = trackingModalData.find(q => q.options);
    const resetSymptoms = {};
    symptomsQuestion?.options.forEach(option => {
      resetSymptoms[option] = false;
    });

    setAnswers({
      symptoms: resetSymptoms,
      stands: ""
    });
    setCurrentStep(0);
    onRestart();
    onClose();
    
    const payload = {
      screen_name: "sit_to_stand",
      tap_value: "restart test",
      User_id: uuid,
      app_aging:appAging,
      screen_name:exerciseData?.data?.exercise_enum
    };
    sendEventsToAnalyticsSystems("sit_to_stand_symptoms_tap", payload, uuid);
  };

  const currentQuestion = getCurrentQuestion();
  const isSymptomQuestion = Boolean(currentQuestion.options);

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-md bg-white rounded-t-2xl p-6">
        <div className="flex justify-between items-center mb-5">
          <div className="flex justify-center gap-2 mb-6 pt-6">
            {trackingModalData.map((_, index) => (
              <div
                key={index}
                className={`h-1.5 w-8 rounded ${
                  index <= currentStep ? "bg-[#3D3D3D]" : "bg-gray-200"
                }`}
              />
            ))}
          </div>
          {currentStep === 0 && (
            <button
              onClick={handleSkip}
              className="text-[#4F514E] text-sm font-semibold"
            >
              {lang === 'hi' ? 'छोडना' : 'Skip'}
            </button>
          )}
        </div>

        <div className="mb-6">
          {isSymptomQuestion ? (
            <div className="space-y-4">
              <h2 className="text-lg font-semibold text-[#171717]">
                {currentQuestion.question}
              </h2>
              <div className="space-y-5">
                {currentQuestion.options.map((option) => (
                  <div
                    key={option}
                    className="flex items-center gap-3 cursor-pointer"
                    onClick={() => handleSymptomChange(option)}
                  >
                    <div
                      className={`w-5 h-5 rounded border ${
                        answers.symptoms[option]
                          ? "border-[#72B53D] bg-[#72B53D]"
                          : "border-gray-300 bg-white"
                      } flex items-center justify-center`}
                    >
                      {answers.symptoms[option] && (
                        <svg className="w-3 h-3 text-white" viewBox="0 0 12 12">
                          <path
                            fill="currentColor"
                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"
                          />
                        </svg>
                      )}
                    </div>
                    <span className="text-base font-medium text-[#333333]">
                      {option}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="">
              <h2 className="text-lg font-semibold text-[#000000]">
                {currentQuestion.question}
              </h2>
              <label className="block text-base font-normal text-[#333333] mt-5">
                {
                  lang === 'hi' ? 'नंबर दर्ज करें. स्टैंड के' : 'Enter no. of stands'
                }
                <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                value={answers.stands}
                min="1"
                max="30"
                onChange={(e) => {
                  setValidationError(""); // Clear validation error when user types
                  setAnswers(prev => ({ ...prev, stands: e.target.value }));
                }}
                placeholder="eg. 5"
                className="w-full mt-2 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#72B53D] focus:border-transparent text-gray-900"
              />
            </div>
          )}
          
          {/* Localized Validation Error Message */}
          {validationError && (
            <div className="mt-2 text-red-500 text-sm">
              {validationError}
            </div>
          )}
        </div>

        <div className="space-y-2">
          <button
            type="button"
            className="w-full py-4 px-4 bg-[#72B53D] text-white font-medium rounded-full text-lg hover:bg-[#65a235] transition-colors"
            onClick={handleContinue}
          >
            {currentStep < trackingModalData.length - 1 
              ? (lang === 'hi' ? 'जारी रखना' : 'Continue')
              : (lang === 'hi' ? 'बचाना' : 'Save')
            }
          </button>
          <button
            type="button"
            className="w-full py-4 px-4 bg-white text-gray-600 font-medium rounded-full text-lg border border-gray-300 hover:bg-gray-50 transition-colors"
            onClick={handleRestart}
          >
            {lang === 'hi' ? 'परीक्षण पुनः प्रारंभ करें' : 'Restart test'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrackingModal;