import React, { useState } from 'react';
import SuccessPopUpPulmo from './SuccessPopUpPulmo';

const PulmoRehabPolicyConsent = () => {
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  const [formData, setFormData] = useState({
    patientId: '',
    name: '',
    age: '',
  });
  const [isAgreed, setIsAgreed] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isFormValid = () => {
    return formData.patientId.trim() !== '' && formData.name.trim() !== '' && formData.age !== '' && isAgreed;
  };
  const handleSubmit = () => {
    if (isFormValid()) {
        setIsSuccessPopupOpen(true);
      console.log('Form submitted:', { ...formData, isAgreed });
    }
  };
  const handleModalCLose = () => {
    setIsSuccessPopupOpen(false);
    
  };
  return (
    <div className="p-4">
      {/* Previous sections remain exactly the same until the checkbox section */}
      <div className="text-center">
        <p className="text-xl font-semibold">Privacy Notice</p>
        <p className="mt-2 text-[#424242] text-xs font-normal italic">
          (Please read this Privacy Notice carefully before signing the Consent Letter)
        </p>
      </div>
      <div className="mt-4">
        <p className="text-base font-semibold text-[#424242]">Purpose-</p>
        <p className="text-xs font-normal text-[#000000] mt-2">
          This Privacy Notice will explain how our organization, Cipla Limited ("Cipla") through our authorized service
          provider Health vista / Team Lease including its trained Pulmonary Rehab Therapists(Service provider ) ") will
          collect, process and store your personal data provided at the time of signing the Consent Letter (annexed
          below), when you agree to participate in the Pulmonary Rehabilitation Program, organized by Cipla for patients
          suffering from chronic respiratory illness, to improve their functional capacity & to improve their quality of
          life. This notice will also explain how you can exercise your data privacy rights and communicate with us for
          redressal of your queries or concerns related to your data.
        </p>
      </div>
      <div className="mt-4">
        <p className="text-base font-semibold text-[#424242]">Personal Data</p>
        <p className="text-xs font-normal text-[#000000] mt-2">
          a) Name <br />
          b) Age <br />
          c) Gender <br />
          d) Phone number/email ID <br />
          e) City/Address -
        </p>
      </div>
      <div className="mt-4">
        <p className="text-base font-semibold text-[#424242]">Medical data and history</p>
        <p className="text-xs font-normal text-[#000000] mt-2">Pulmonary Condition</p>
        <p className="text-xs font-normal text-[#000000]">
          <ul className="list-disc px-2">
            <li>Blood pressure</li>
            <li>Heart Rate</li>
            <li>Spo2 level</li>
            <li>Physical fitness test ( 1 minute sit to stand / . 6 minute walk test )</li>
            <li>Oxygen support duration</li>
            <li>PFT report (to be provided by the patients to the Service Provider)</li>
            <li>EQ5D - Quality of life score</li>
            <li>other Co-morbidities</li>
          </ul>
        </p>
        <p className="text-xs font-normal text-[#000000] mt-3">
          Your personal data and medical data will be used and processed to facilitate the Purpose and any anonymized
          non-personal data generated therefrom for related research activity.
        </p>
      </div>
      <div className="mt-4">
        <p className="text-base font-semibold text-[#424242]">What are your data protection rights-</p>
        <p className="text-xs font-normal text-[#000000] mt-2">
          You can request access to the information we process about you at any time. If at any point you believe that
          the Personal Data we process relating to you is incorrect, you can request to see this information and have it
          corrected, restricted, or erased. You also have the right to withdraw your consent at any time.
        </p>
      </div>
      <div className="mt-4">
        <p className="text-base font-semibold text-[#424242]">Grievance mechanism-</p>
        <p className="text-xs font-normal text-[#000000] mt-2">
          If you wish to raise a complaint on how we have handled your Personal Data, including any queries on
          collection, processing, use, storage, protection, erasure, and retention of Personal Data can be obtained by
          writing to us at globalprivacy@cipla.com and any complaints for correction, updating and erasure of your
          personal data can be obtained by writing to ethics@cipla.com If you are not satisfied with our response or
          believe we are not processing your personal data in accordance with the applicable laws, you can raise a
          complaint with the appropriate authority as prescribed and notified under applicable laws.
        </p>
      </div>
      <div className="mt-6">
        <p className="text-xl font-semibold text-center uppercase">CONSENT FORM</p>
        <p className="mt-2 text-[#000000] text-xs font-normal">
          <span className="font-semibold">Note:</span> This form must be fully read, understood, and signed/agreed to by
          the patient before agreeing to take part in the Pulmonary Rehabilitation Program.
        </p>
        <div className="text-[#424242] font-semibold mt-6 flex">
          <p>Patient ID:</p>
          <input
            type="text"
            name="patientId"
            value={formData.patientId}
            onChange={handleInputChange}
            className="border-b-2 border-[#000000] outline-none ml-2 w-20"
          />
        </div>
        <div className="flex items-center text-sm font-semibold mt-2">
          <p className="mr-1">I,</p>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-2/4 border-2 border-[#D8D8D8] bg-[#FBFBFB] rounded-lg py-4 transition-all duration-300 outline-none pl-1"
          />
          <p className="mx-1">,aged</p>
          <input
            type="number"
            name="age"
            value={formData.age}
            onChange={handleInputChange}
            className="w-1/4 border-2 border-[#D8D8D8] bg-[#FBFBFB] rounded-lg py-4 transition-all duration-300 outline-none pl-1"
          />
        </div>
        <p className="text-base font-semibold text-[#424242] mt-3">hereby consent to the following –</p>
      </div>
      <div className="text-xs mt-6 px-3">
        <ol className="list-decimal">
          <li>
            I hereby agree to take the Pulmonary Rehabilitation Program organised by Cipla and conducted by the trained
            Pulmonary Rehabilitation therapists of the Service Provider. I understand that the treatment provided to me
            can have risks and benefits while participating in Pulmonary Rehabilitation Program. The potential risks
            include fatigue or exacerbation of symptoms. The potential benefits include improved respiratory function,
            increased exercise capacity, and enhanced quality of life which all are informed to me by therapist.
          </li>
          <li className="mt-4">
            I understand that for any medical emergency, specific health-related concerns or queries, proper diagnosis
            of my medical condition or before starting with any medication or treatment, a registered medical
            practitioner should be consulted.
          </li>
          <li className="mt-4">
            I confirm that I have fully read and understood the contents of the Privacy Notice and I hereby consent to
            share my Personal Data for the Purpose mentioned in the Privacy Notice.
          </li>
          <li className="mt-4">
            I understand that Cipla and the Service Provider shall under applicable laws, be responsible and liable for
            any misuse of the Personal Data
          </li>
          <li className="mt-4">
            I have been informed and notified of my rights to correct, update, and erase my Personal Data or withdraw my
            consent at any time.
          </li>
          <li className="mt-4">
            I agree and accept that this consent provided by me is voluntary and is not subject to any terms and
            conditions, commercial or otherwise.
          </li>
          <li className="mt-4">
            I understand that this consent shall be valid till I myself withdraw for same if requested by me.
          </li>
        </ol>
      </div>
      <div className="mt-6">
        <p className="text-xs font-medium">
          For any queries with respect to the initiative and pulmonary rehabilitation, please contact{' '}
          <span className="font-bold">9714256415</span>{' '}
        </p>
      </div>
      <div className="mt-6">
        <div className="">
          <div className="flex items-center">
            <label className="custom-checkbox">
              <input
                type="checkbox"
                id="agreement"
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
              />
              <span className="checkmark">
                <svg viewBox="0 0 24 24">
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span className="text-xs font-normal mt-1">
                By clicking on the I agree check box, you confirm that <br />
              </span>
            </label>
          </div>
          <p className="text-xs font-normal mt-1 leading-4">
            the contents of the Privacy Notice, this Consent Form and the rights available to me are fully understood by
            me and accordingly accepted by me.
          </p>
          <button
            className={`mt-6 px-4 py-2 flex justify-center items-center gap-2 ${
              isFormValid() ? 'bg-[#72B53D] text-white cursor-pointer' : 'bg-gray-300 text-gray-600 cursor-not-allowed'
            } text-base font-bold border-2  rounded-full w-full`}
            disabled={!isFormValid()}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
      <SuccessPopUpPulmo  isOpen={isSuccessPopupOpen} onClose={handleModalCLose} />
    </div>
  );
};

export default PulmoRehabPolicyConsent;
