import React from 'react';
import progressArrow from '../../assests/images/progressArrow.png';

const SimpleGreenProgressBar = ({ value , max ,exercise_enum }) => {
  // Calculate percentage
  const maxValue = exercise_enum === "sit_to_stand_test" ? 30 : 60;
  const percentage = (value / maxValue) * 100;

  return (
    <div className="w-full max-w-md mt-6">
      <div className="relative">
        {/* Value marker with progressArrow */}
        <div 
          className="absolute transform -translate-x-1/2 -top-4"
          style={{ 
            left: `calc(${percentage}% - ${percentage === 100 ? '16px' : '0px'})` 
          }}
        >
          <div className="flex flex-col items-center">
            <img src={progressArrow} alt="progress arrow" />
          </div>
        </div>

        {/* Progress bar container */}
        <div className="relative h-4 rounded-full overflow-hidden bg-[#518A17]">
          {/* Added subtle gradient for depth */}
          <div 
            className="h-full w-full"
            // style={{
            //   background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%)'
            // }}
          />
        </div>

        {/* Scale markers */}
        <div className="relative h-8 mt-1">
          <div className="absolute left-0 text-sm font-medium text-gray-700">0</div>
          <div className="absolute right-0 text-sm font-medium text-gray-700">{maxValue}</div>
        </div>
      </div>
    </div>
  );
};

export default SimpleGreenProgressBar;