import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import FinalResultSitStand from "./FinalResultSitStand";
import closeIcon from "../../assests/images/closeIcon.svg";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
import { FeedbackSection } from "./FeedbackSection";
import { LungCapacitySection } from "./LungCapacitySection";
import LungCapacityResult from "./LungCapacityResult";
import Loader from "../exercise/Loader";
import { BASE_URL } from "../../utils/ApiConstants";
import axios from "axios";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
const ResultScreen = () => {
  const { state } = useLocation();
  const [resultData, setResultData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  
  useEffect(() => {
    fetchResult();
  }, [state.accessToken, state.exercise_enum,state.lang,state.uuid, state.appVersion, state.appAging]);
  const formatTimeString = (timeString) => {
    if (!timeString) return "";
    try {

      const [hours, minutes] = timeString.split(":");
      const date = new Date();
      date.setHours(parseInt(hours), parseInt(minutes));
      return format(date, "h:mm aa");
    } catch (err) {
      console.error("Error formatting time:", err);
      return timeString;
    }
  };

  const getFormattedDateTime = (timeData) => {

    const formattedTime = formatTimeString(timeData?.time);
    const time = formatInTimeZone(new Date(timeData?.time), "Asia/Kolkata","hh:mm aa");
    
    return timeData?.day ? `${timeData.day}, ${time}` : formattedTime;
  };
  
  const fetchResult = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASE_URL}/exercise-test/result`,
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          appVersion: state.appVersion,
          deviceBrand: "OnePlus",
          deviceModel: "HD1901",
          device_name: "OnePlus 7TT",
          deviceVersion: "12",
          platform: "android",
          timestamp: Date.now(),
          accesstoken: state.accessToken,
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          exercise_enum: state.exercise_enum,
        },
      });

      const newResultData = response.data;
      setResultData(newResultData);
      const exerciseName = state.exercise_enum === 'sit_to_stand_test' ? 'SittoStandResult' : 'BreatheHoldTestResult';
      const payload = {
        screen_name: state.exercise_enum,
        your_previous: newResultData?.data?.score_interpretation?.previous_score?.score,
        your_best: newResultData?.data?.score_interpretation?.best_score?.score,
        your_current: newResultData?.data?.score_interpretation?.score,
        result_title: newResultData?.data?.score_interpretation?.title,
        User_id: state.uuid,
        app_aging:state.appAging,
        session_source:'organic',
        screen_name: exerciseName,
      };
      sendEventsToAnalyticsSystems("BE_result_screen", payload,state.uuid);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  const onClose = () => {
    let reactNativePayload = {
      key: 207,
      type: "HIDE_INTERNAL_PWA",
    };

    if (window?.ReactNativeWebView) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify(reactNativePayload)
      );
    }
  };
  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className="w-full max-w-2xl px-5 py-2">Error: {error}</div>;
  }

  return (
    <div className="w-full min-h-svh max-w-2xl px-5 py-2 bg-white">
      <div className="relative w-full max-w-sm px-4 pt-3">
        <button
          onClick={onClose}
          className="absolute right-0 top-2 p-1 rounded-full hover:bg-gray-100"
          aria-label="Close"
        >
          <img src={closeIcon} />
        </button>

        <div className="text-center">
          <p className="text-2xl font-bold text-center">
            {resultData?.data?.header}
          </p>
          <p className="mt-1.5 text-xs text-[#A7A7A7] font-normal leading-5">
          {getFormattedDateTime(resultData?.data?.time)}
          </p>
        </div>
      </div>
      <LungCapacityResult
        data={resultData?.data?.score_interpretation}
        exercise_enum={state.exercise_enum}
        lang={state.lang}
        accessToken={state.accessToken}
        uuid={state.uuid}
      />
      {/* <div className="mt-5 border-[1px] border-[#E6E6E6] rounded-md text-center py-6 px-3 bg-[#FCFCFC]">
        <div>
          <p className="text-xl text-[#E84545] font-bold">
            You have poor lung capacity
          </p>
          <p className=" text-base font-normal">
            holding breath for <span className="font-bold">8s</span>{" "}
          </p>
        </div>
        <div className="pt-2">
          <FinalResult value={60} max={60} />
          <FinalResultSitStand value={30} max={30} />
        </div>
        <div className="flex mt-10 justify-around">
          <div className="">
            <p className="uppercase text-sm text-[#A7A7A7] font-normal">
              Your Previous
            </p>
            <p className="text-xs text-[#212121] font-normal">
              <span className="font-semibold pr-1">5s</span>Today, 10:13 am
            </p>
          </div>
          <div>
            <p className="uppercase text-sm text-[#A7A7A7] font-normal">
              Your Best
            </p>
            <p className="text-xs text-[#212121] font-normal">
              <span className="font-semibold pr-1">5s</span>Today, 10:13 am
            </p>
          </div>
        </div>
        <div className="text-center mt-5">
          <Link className="text-[#979797] text-xs font-semibold uppercase underline-offset-2 underline">
            View Your Results Summary
          </Link>
        </div>
      </div> */}
      {resultData?.data?.show_feedback && (
        <FeedbackSection
          accessToken={state.accessToken}
          exercise_enum={state.exercise_enum}
          lang={state.lang}
          uuid={state.uuid}
          app_aging={state.appAging}
        />
      )}
      {resultData?.data?.action?.data.length !== 0 && (
        <LungCapacitySection
          data={resultData?.data?.action}
          exercise_enum={state.exercise_enum}
          uuid={state.uuid}
          app_aging={state.appAging}
        />
      )}
    </div>
  );
};

export default ResultScreen;
