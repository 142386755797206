import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import playicon from "../../assests/images/playicon.png";
import stopIcon from "../../assests/images/Rectangle.png";
import info from "../../assests/images/info.png";
import ExerciseModal from "./ExerciseModal";
import TrackingModal from "./TrackingModal";
import axios from "axios";
import { BASE_URL } from "../../utils/ApiConstants";
import Loader from "../exercise/Loader";
import Backicon from "../../assests/images/BackIcon.png";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";

const ExerciseStart = () => {
  const { exercise_enum } = useParams();
  const navigate = useNavigate();
  // States
  const [exerciseData, setExerciseData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [watchDuration, setWatchDuration] = useState(0);
  const [showStopButton, setShowStopButton] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(true);
  // Modal states
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isTrackingOpen, setIsTrackingOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [uuid, setUuid] = useState("");
  const [accessToken, setaccessToken] = useState("");
  const [lang, setLang] = useState("");
  const [appVersion, setAppVersion] = useState("");
  const [appAging, setAppAging] = useState("");
  // Refs
  const videoRef = useRef(null);
  const timerRef = useRef(null);
  useEffect(() => {
    const listenMessage = (msg) => {
      try {
        // Check if msg.data exists
        if (!msg.data) return;

        // Handle the message based on its type
        let messageData;
        if (typeof msg.data === 'string') {
          // Only try to parse if it's a string and looks like JSON
          if (msg.data.startsWith('{') || msg.data.startsWith('[')) {
            try {
              messageData = JSON.parse(msg.data);
            } catch (e) {
              console.warn('Failed to parse message data as JSON:', e);
              return;
            }
          } else {
            // Not JSON string, skip processing
            return;
          }
        } else if (typeof msg.data === 'object') {
          // If it's already an object, use it directly
          messageData = msg.data;
        } else {
          // Unknown data type, skip processing
          return;
        }

        // Extract profile data if it exists
        const profileData = messageData?.data?.profileData;
        if (profileData) {
          setUuid(profileData.uuid || '');
          setaccessToken(profileData.accessToken || '');
          setLang(profileData.language || '');
          setAppVersion(profileData.appVersion || '');
          setAppAging(profileData.appAging || '')
        }
      } catch (error) {
        console.warn('Error processing message:', error); 
      }
    };

    // Add event listeners
    if (document.addEventListener) {
      document.addEventListener("message", listenMessage, false);
    } else if (document.attachEvent) {
      document.attachEvent("onmessage", listenMessage);
    }

    if (window.addEventListener) {
      window.addEventListener("message", listenMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", listenMessage);
    }

    // Cleanup function
    return () => {
      if (document.removeEventListener) {
        document.removeEventListener("message", listenMessage);
      } else if (document.detachEvent) {
        document.detachEvent("onmessage", listenMessage);
      }

      if (window.removeEventListener) {
        window.removeEventListener("message", listenMessage);
      } else if (window.detachEvent) {
        window.detachEvent("onmessage", listenMessage);
      }
    };
  }, []);
  // useEffect(() => {
  //   window.addEventListener("message", (message) => {
  //     console.log(message.data.profileData); // Wayne is coming!!!
  //     setUuid(message?.data?.profileData?.uuid);
  //     setwebviewaccessToken(message?.data?.profileData?.accessToken);
  //     setLang(message?.data?.profileData?.language);
  //   });
  // }, []);
  // const accessToken = webviewaccessToken;
  const api = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      appVersion: appVersion,
      deviceBrand: "OnePlus",
      deviceModel: "HD1901",
      device_name: "OnePlus 7TT",
      deviceVersion: "12",
      platform: "android",
      "Access-Control-Allow-Origin": "*",
      accesstoken: accessToken,
    },
  });
  // Video control handlers
  const startPlaying = () => {
    if (videoRef.current && !isVideoLoading) {
      videoRef.current.play();
      setShowThumbnail(false);
      setIsPlaying(true);
      timerRef.current = setInterval(() => {
        setWatchDuration((prev) => prev + 1);
      }, 1000);
      setTimeout(() => {
        setShowStopButton(true);
      }, 1000);
      const payload = {
        source_name: exercise_enum,
        userid: uuid,
        app_aging:appAging
      };
      sendEventsToAnalyticsSystems("exercise_listing_screen_start_tap", payload,uuid);
    }
  };
  useEffect(() => {
    let reactNativePayload = { type: "HIDE_BACK_ARROW", key: 205 };
    window?.ReactNativeWebView?.postMessage(JSON.stringify(reactNativePayload));
  }, []);
  const handleBackToHome = () => {
    let reactNativePayload = { type: "HIDE_INTERNAL_PWA", key: 207 };
    window?.ReactNativeWebView?.postMessage(JSON.stringify(reactNativePayload));
    // sendEventsToAnalyticsSystems('back_button_clicked');
  };
  const handleStop = async () => {
    setShowStopButton(false);
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      if (exercise_enum === "breathe_hold_test") {
        console.log("Breathe Hold Test");

        try {
          await sendWatchDuration(watchDuration);
        } catch (err) {
          console.error("Error sending watch duration:", err);
        }
      } else {
        setIsTrackingOpen(true);
      }
    }
  };
useEffect(() => {
  const payload = {
    source_name: exercise_enum,
    userid: uuid,
    app_aging:appAging
  };
  sendEventsToAnalyticsSystems("exercise_listing_screen", payload,uuid);
}, []);

  // Modal handlers
  const openInfoModal = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
    setIsInfoOpen(true);
  };
  const closeInfoModal = () => setIsInfoOpen(false);
  const closeTrackingModal = () => setIsTrackingOpen(false);

  const nextStep = () =>
    setCurrentStep((prev) =>
      prev < exerciseData?.data?.steps_image_collection?.length - 1
        ? prev + 1
        : prev
    );
  const prevStep = () => setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));

  const handleTrackingSubmit = async (trackingData) => {
    try {
      // Only send watch duration for non-sit-to-stand exercises
      if (exercise_enum === "breathe_hold_test") {
        await sendWatchDuration(watchDuration);
      }
    } catch (err) {
      console.error("Error in tracking submit:", err);
    }
    closeTrackingModal();
  };

  const sendWatchDuration = async (duration) => {
    try {
      const payload = {
        exercise_id: exerciseData?.data?.exercise_id,
        exercise_enum: exerciseData?.data?.exercise_enum,
        breathe_holding_capacity: duration,
      };

      const response = await api.post("/add/exercise-test/response", payload, {
        headers: {
          timestamp: Date.now().toString(),
          accessToken:accessToken
        },
      });
      if (response?.data?.response?.code === 200) {
        navigate("/result-screen", {
          state: { accessToken: accessToken, exercise_enum: exercise_enum,lang:lang,uuid:uuid },
        });
      }
    } catch (error) {
      console.error("Error sending watch duration:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchExerciseDetails = async () => {
      try {
        const { data } = await api.post("/breathing/exercise/detail", {
          exercise_enum: exercise_enum,
        });
        setExerciseData(data);
      } catch (error) {
        console.error("Error fetching exercise details:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    if(accessToken){
      fetchExerciseDetails();
    }
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [accessToken, lang, uuid, appVersion]);
  const startVideoFromBeginning = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setIsPlaying(true);
      setShowThumbnail(false);
      
      // Clear any existing timer
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      
      // Reset and start the watch duration timer
      setWatchDuration(0);
      timerRef.current = setInterval(() => {
        setWatchDuration((prev) => prev + 1);
      }, 1000);
      
      // Show the stop button after a short delay
      setTimeout(() => {
        setShowStopButton(true);
      }, 1000);
    }
  };
  

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  const practiceVideoUrl = exerciseData?.data?.instruction_video_url
    ? `${process.env.REACT_APP_S3_BUCKET_URL}/${exerciseData.data.instruction_video_url}`
    : "";
    const thumbnailUrl = exerciseData?.data?.thumbnail_image
      ? `${process.env.REACT_APP_S3_BUCKET_URL}/${exerciseData.data.thumbnail_image}`
      : "/api/placeholder/400/320";
  const ImageURL = `${process.env.REACT_APP_S3_BUCKET_URL}`;

  return (
    <div className="p-4 bg-white min-h-svh">
      <div className="relative w-full max-w-sm py-3">
        <div className="relative text-center">
          <button
            onClick={handleBackToHome}
            className="absolute left-0 top-1/2 -translate-y-1/2"
          >
            <img src={Backicon} alt="Back Arrow Icon" />
          </button>

          <p className="mx-12 text-xl font-semibold text-[#171717]">
            {exerciseData?.data?.exercise_description}
          </p>

          {isPlaying && (
            <button
              onClick={openInfoModal}
              className="absolute right-0 top-1/2 -translate-y-1/2 p-1"
              aria-label="Info"
            >
              <img src={info} alt="Info" />
            </button>
          )}
        </div>
      </div>

      <div className="flex items-center justify-center h-[calc(100vh-200px)]">
        <div className="relative flex flex-col items-center justify-center flex-1 w-full">
          {isVideoLoading && <div>Loading video...</div>}
          {showThumbnail && (
            <div className="absolute inset-0 z-10">
              <img
                src={thumbnailUrl}
                alt="Video thumbnail"
                className="w-full h-full object-cover rounded-lg"
              />
              {/* <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                <img src={playicon} alt="Play" className="w-16 h-16" />
              </div> */}
            </div>
          )}
          {practiceVideoUrl && (
            <video
              ref={videoRef}
              className="h-full w-full rounded-lg"
              controls={false}
              key={practiceVideoUrl}
              onEnded={handleStop}
              onLoadedData={() => setIsVideoLoading(false)}
              onCanPlay={() => setIsVideoLoading(false)}
              onLoadedMetadata={() => setIsVideoLoading(false)}
              playsInline
            >
              <source src={`${practiceVideoUrl}#t=0.011`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>

        <div className="fixed bottom-5 left-5 right-5 ">
          {isPlaying ? (
            showStopButton && (
              <button
                className="px-4 py-4 flex justify-center items-center gap-2 bg-white text-[#72B53D] border-2 border-[#72B53D] text-xl rounded-full w-full"
                onClick={handleStop}
              >
                <div className="flex items-center text-xl gap-2 justify-center font-semibold">
                  <img src={stopIcon} alt="Stop" />
                  {lang === "hi" ? "रुकना" : "Stop"}
                </div>
              </button>
            )
          ) : (
            <button
              className={`px-4 py-4 flex justify-center items-center gap-2 bg-[#72B53D] text-white border-2 border-[#72B53D] text-xl rounded-full w-full ${
                isVideoLoading ? "cursor-not-allowed" : ""
              }`}
              onClick={startPlaying}
              disabled={isVideoLoading}
            >
              <div className="flex items-center text-xl gap-2 justify-center font-semibold">
                <img src={playicon} alt="Play" />
                {lang === "hi" ? "शुरू" : "Start"}
              </div>
            </button>
          )}
        </div>
      </div>

      {isInfoOpen && (
        <ExerciseModal
          isOpen={isInfoOpen}
          closeModal={closeInfoModal}
          steps={exerciseData?.data?.steps_image_collection || []}
          currentStep={currentStep}
          prevStep={prevStep}
          nextStep={nextStep}
          ImageURL={ImageURL}
          exerciseDetails={exerciseData}
          exercise={exerciseData?.data}
          selectedRounds={1}
          selectedLevel="beginner"
          selectedDuration={300}
          startVideoFromBeginning={startVideoFromBeginning}
          lang={lang}
          uuid={uuid}
          appAging={appAging}
        />
      )}

      <TrackingModal
        isOpen={isTrackingOpen}
        onClose={closeTrackingModal}
        onSubmit={handleTrackingSubmit}
        onRestart={startVideoFromBeginning}
        exerciseData={exerciseData}
        accessToken={accessToken}
        lang={lang}
        uuid={uuid}
        appAging={appAging}
      />
    </div>
  );
};

export default ExerciseStart;
