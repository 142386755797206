import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import crossWithWhiteBg from '../../assests/images/crossWithWhiteBg.png'
import { sendEventsToAnalyticsSystems } from '../../utils/analytics';
import playicon from "../../assests/images/playicon.png";
// import { sendEventsToAnalyticsSystems } from '../../utils/analytics';
const ExerciseModal = ({ isOpen, closeModal, startVideoFromBeginning,steps, currentStep, prevStep, nextStep, ImageURL, exerciseDetails, exercise, selectedRounds, selectedLevel, selectedDuration,lang,uuid,isFirstTimeUser = false,appAging }) => {


  useEffect(() => {
    sendEventsToAnalyticsSystems("BE_intro_screen", {
      screen_name: exerciseDetails?.data?.exercise_enum,
      // exerciseId: exercise.exercise_id,
      exerciseType: exerciseDetails?.data?.exercise_type,
      User_id:uuid,
      app_aging:appAging,
      session_source:'organic',
      screen_name: exerciseDetails?.data?.exercise_enum,
    },uuid)
  }, [])
  
  if (!isOpen) return null;
  // console.log(exerciseDetails?.data?.exercise_enum
  //   ,'exerciseDetails?.data?.title')
  const sendAnalyticsEvent = (tapType) => {
    const payload = {
      exerciseName: exerciseDetails?.data?.exercise_enum,
      exerciseType: exerciseDetails?.data?.exercise_type,
      tutorialPage: steps[currentStep].title,
      tapType: tapType,
      User_id:uuid,
      app_aging:appAging,
      screen_name: exerciseDetails?.data?.exercise_enum,
      session_source:'organic'
    };
    sendEventsToAnalyticsSystems("BE_intro_screen_tap", payload,uuid
      // ,localStorage.getItem("secondary_uuid")
    );
  };


  const handlePrevStep = () => {
    sendAnalyticsEvent("previous");
    prevStep();
  };

  const handleNextStep = () => {
    sendAnalyticsEvent("next");
    nextStep();
  };
  const handlePreStart=()=>{
    sendEventsToAnalyticsSystems("BE_intro_screen_final_tap", {
      exerciseName: exerciseDetails?.data?.exercise_enum,
      // exerciseId: exercise.exercise_id,
      exerciseType: exerciseDetails?.data?.exercise_type,
      tapType: 'Resume',
      User_id:uuid,
      app_aging:appAging,
      screen_name: exerciseDetails?.data?.exercise_enum,
      session_source:'organic'
    }
    ,uuid
  );
    closeModal();
    startVideoFromBeginning();
  }
  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center bg-black bg-opacity-50 h-75">
    <div className="relative w-full max-w-md bg-white rounded-t-2xl p-6">
      <div className="mb-4">
        <h3 className="text-lg font-medium text-[#171717]">
          {
            lang === 'hi' ? 'परीक्षण के बारे में और जानें' : 'Learn more about exercise'
          } 
        </h3>
        <div className='mt-3 border-b-2 border-[#EAEAEA] w-full'></div>
        <button
          onClick={closeModal}
          className="absolute -top-10 right-3 z-10 w-8 h-8 flex items-center justify-center bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
        >
          <img src={crossWithWhiteBg} alt="Close" className="w-6 h-6" />
        </button>
      </div>

      <div className="mb-2 px-6 py-6 bg-[#F7F7F7] rounded-2xl">
        <h3 className="text-lg font-semibold">{steps[currentStep].title}</h3>
        <p className="text-sm text-[#9A9A9A]">
          {steps[currentStep].description}
        </p>

        <img
          src={`${ImageURL}/${steps[currentStep].image}`}
          alt={steps[currentStep].title}
          className="w-full h-[300px] object-cover mb-4"
        />
      </div>

      <div className="flex justify-between gap-4">
        {currentStep > 0 && (
          <button
            type="button"
            className="px-4 py-4 bg-white text-[#72B53D] border-2 border-[#72B53D] text-xl rounded-full w-full disabled:opacity-50"
            onClick={handlePrevStep}
            disabled={currentStep === 0}
          >
            {lang === "hi" ? "पहले का" : "Previous"}
          </button>
        )}
        {currentStep < steps.length - 1 ? (
          <button
            type="button"
            className={`px-4 py-4 rounded-full ${
              currentStep > 0
                ? "bg-[#72B53D] text-white font-semibold w-full text-xl"
                : "bg-[#72B53D] text-white font-semibold text-xl w-full"
            }`}
            onClick={handleNextStep}
          >
            {lang === "hi" ? "अगला" : "Next"}
          </button>
        ) : (
          <div
            className="px-4 py-2 bg-[#72B53D] text-white rounded-full w-full"
          >
            <button
              type="button"
              onClick={handlePreStart}
              className="flex items-center gap-2 px-4 py-2 text-white font-semibold text-xl rounded w-full"
            >
              <img src={playicon} alt="Play" />
              {lang === "hi" ? "शुरू करें" : "Resume"}
            </button>
          </div>
        )}
      </div>
    </div>
  </div>
  );
};

export default ExerciseModal;